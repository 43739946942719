import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '@/context';
import {
  CexCurrency,
  CexCurrencyCorridor,
  getCexCorridors,
  getCurrencyList,
} from '@/services';

export const useCexCurrencies = () => {
  const router = useRouter();

  const {
    currencies: initialCurrencies,
    corridors: initialCorridors,
    sendCountry,
    receiveCountry,
  } = useContext(AppContext);

  const [currencies, setCurrencies] = useState<CexCurrency[] | null>(null);
  const [corridors, setCorridors] = useState<CexCurrencyCorridor[] | null>(
    null,
  );

  /**
   * Fetch currency list
   */
  useEffect(() => {
    if (!initialCurrencies?.length && currencies === null) {
      getCurrencyList({
        sendCountryCode: sendCountry?.countryCode ?? '',
        receiveCountryCode: receiveCountry?.countryCode ?? '',
        locale: router.locale,
      }).then(currencyList => {
        setCurrencies(currencyList);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveCountry?.countryCode, sendCountry?.countryCode, router.locale]);

  /**
   * Fetch corridor list
   */
  useEffect(() => {
    if (!initialCorridors?.length && corridors === null) {
      const fetchCorridorList = async () => {
        await getCexCorridors().then(corridorList => {
          setCorridors(corridorList);
        });
      };

      fetchCorridorList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveCountry?.countryCode, sendCountry?.countryCode, router.locale]);

  return {
    currencies: initialCurrencies?.length
      ? initialCurrencies
      : currencies || [],
    corridors: initialCorridors?.length ? initialCorridors : corridors || [],
  };
};
