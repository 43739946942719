import { sendwaveApiTypes } from '@/types';

export type CountryDataProps = Pick<
  sendwaveApiTypes.components['schemas']['CountryResponse'],
  | 'currency'
  | 'iso2'
  | 'name'
  | 'send'
  | 'receive'
  | 'validReceiveCountriesIso2'
  | 'payoutMethods'
  | 'sendLimits'
  | 'currencyDisplay'
  | 'shortCurrencyPrefix'
  | 'intl'
  | 'currencyQuant'
  | 'iso3'
  | 'timezone'
  | 'receiveLimits'
  | 'defaultLocales'
  | 'canReceiveBank'
  | 'canReceiveMobile'
> & {
  validSendCountriesIso2?: string[];
};

type ParsedCountryData = CountryData & {
  currency: Exclude<CountryData['currency'], null | undefined>;
  currencyName: string;
  countryCode: string;
};

export type CountryData = CountryDataProps;

export const parseCountryData = (country: CountryData) => {
  return {
    ...country,
    currency: country.currency || '',
    currencyName: country.currencyDisplay || '',
    countryCode: country.iso2.toUpperCase(),
  } as ParsedCountryData;
};

export const parseCountryDataPartial = (country?: CountryData | null) => {
  return country ? parseCountryData(country) : undefined;
};
