import { StoreApi, UseBoundStore } from 'zustand';

import { FaqStoreState, hydrateFaqState, useFaqsStore } from '@/state';

import { useStoreSync } from './useStoreSync.hook';

type State = FaqStoreState; // add more states here to support state types

export const ModuleStores: Record<
  string,
  (props: { [x: string]: unknown }) => UseBoundStore<StoreApi<State>>
> = {
  FaqSection: props =>
    useStoreSync<FaqStoreState>(useFaqsStore, hydrateFaqState(props)),
};
