import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { INITIAL_SEND_AMOUNT, ONE_WEEK_EXPIRATION } from '@/constants';

export const useSendAmount = () => {
  const router = useRouter();
  const [values, setValues] = useState({
    SEND: INITIAL_SEND_AMOUNT,
    RECEIVE: 0,
  });

  const extractSendAmountFromUrl = useCallback(() => {
    const path = router.asPath;
    const amountMatch = path.match(/\/currency-converter\/(\d+)-/);
    return amountMatch ? parseInt(amountMatch[1], 10) : null;
  }, [router.asPath]);

  useEffect(() => {
    const urlAmount = extractSendAmountFromUrl();
    const cookiesAmount = getCookie('urlSendAmount');

    let initialSendAmount = INITIAL_SEND_AMOUNT;

    if (urlAmount !== null) {
      initialSendAmount = urlAmount;
      setCookie('urlSendAmount', urlAmount, { path: '/' });
    } else if (cookiesAmount) {
      initialSendAmount = parseInt(cookiesAmount.toString(), 10);
    }

    setValues(prevValues => ({
      ...prevValues,
      SEND: initialSendAmount,
    }));
  }, [router.asPath, extractSendAmountFromUrl]);

  const updateSendAmount = useCallback((amount: number) => {
    setValues(prevValues => ({
      ...prevValues,
      SEND: amount,
    }));

    setCookie('urlSendAmount', amount, { maxAge: ONE_WEEK_EXPIRATION });
  }, []);

  return {
    values,
    setValues,
    updateSendAmount,
    extractSendAmountFromUrl,
  };
};
