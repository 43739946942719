import { contentfulSchema } from '@wr/web-shared';

import { PageQueryResult } from '../page';

export const selectOrganizationMarkup = (
  data: contentfulSchema.PageDataQuery | null,
  page: PageQueryResult,
):
  | NonNullable<
      NonNullable<
        contentfulSchema.PageDataQuery['seoOrganizationStructuredDataCollection']
      >['items']
    >[0]
  | null => {
  if (!data?.seoOrganizationStructuredDataCollection?.items?.[0]) return null;
  const organizationMarkup = {
    ...data?.seoOrganizationStructuredDataCollection?.items?.[0],
  };

  // override generic seo social image with image specified for the current page
  if (page?.ogImage) {
    organizationMarkup.image = page?.ogImage;
  }

  return organizationMarkup;
};
