import { sendwaveApiTypes } from '@/types';

import { RestResponse } from './rest.types';
import { restParser } from './rest.utils';

export type PricingPublicParams = sendwaveApiTypes.paths['/v2/pricing-public']['get']['parameters']['query'];
export type PricingSegmentsParams = sendwaveApiTypes.paths['/v2/pricing-segments']['get']['parameters']['query'];

export type PricingPublicData = sendwaveApiTypes.components['schemas']['PricingResponse'];
// export type PricingSegmentsData = components['schemas']['PayoutMethodAndSegmentPricingResponse'];
// We need to override this code to fix a defect in the type definition provided by the swagger API
// TODO: Remove this override once the swagger API is fixed
export type Segments = Omit<
  sendwaveApiTypes.components['schemas']['PayoutMethodAndSegmentPricingResponse']['payoutMethodsAndPrices'],
  'segments'
> & {
  readonly isBestPricedPayoutMethod: boolean;
  readonly bestPricedSegmentName: string;
  readonly label: string;
  segments:
    | {
        readonly segmentDisplayName: string;
        readonly description: string;
        readonly segmentName: string;
      }[]
    | undefined;
};

export type PricingSegmentsData = Omit<
  sendwaveApiTypes.components['schemas']['PayoutMethodAndSegmentPricingResponse'],
  'payoutMethodsAndPrices'
> & {
  readonly payoutMethodsAndPrices: Segments[] | undefined;
};

export const getPublic = async (
  params: PricingPublicParams,
  locale?: string,
): Promise<RestResponse<PricingPublicData>> => {
  return await restParser(
    `${process.env.NEXT_PUBLIC_API_URL}/v2/pricing-public`,
    {
      method: 'GET',
      params,
      headers: {
        'Accept-Language': locale || 'en',
      },
    },
  );
};

export const getSegments = async (
  params: PricingSegmentsParams,
  locale?: string,
): Promise<RestResponse<PricingSegmentsData>> => {
  return await restParser(
    `${process.env.NEXT_PUBLIC_API_URL}/v2/pricing-segments`,
    {
      method: 'GET',
      params,
      headers: {
        'Accept-Language': locale || 'en',
      },
    },
  );
};
