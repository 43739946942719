import React from 'react';

const MAX_STACK_LINES = 20;

const sliceErrorStack = (stackTrace = '', numLines = MAX_STACK_LINES) => {
  const lines = stackTrace.split('\n');
  const firstNLines = lines.slice(0, numLines);
  const joinedLines = firstNLines.join('\n');
  return joinedLines;
};

/** Component is used only on test envs for more convenient debug */
export function ErrorDetails({
  error,
  stackTraceClass,
}: {
  error: Error;
  stackTraceClass: string;
}) {
  return (
    <>
      <h5>Error Details</h5>
      <p>{error.message}</p>
      <details>
        <summary>Expand to Show Error Stack Trace</summary>
        <h5>Stack Trace</h5>
        <pre className={stackTraceClass}>{sliceErrorStack(error.stack)}</pre>
      </details>
    </>
  );
}
