import { CountryData as ParsedCountryData } from './country.utils';
import { RestResponse } from './rest.types';
import { restParser } from './rest.utils';

export type CountryData = ParsedCountryData;

export const get = async (
  locale?: string,
): Promise<RestResponse<CountryData[]>> => {
  const res = await restParser<CountryData[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/v2/countries`,
    {
      headers: {
        'Accept-Language': locale || 'en',
      },
    },
  );

  // filter out unnecessary data
  if (res.ok && res.data) {
    return {
      ...res,
      data:
        res?.data?.map(country => {
          const {
            currency,
            iso2,
            name,
            send,
            receive,
            validReceiveCountriesIso2,
            payoutMethods,
            sendLimits,
            currencyDisplay,
            shortCurrencyPrefix,
            intl,
            currencyQuant,
            iso3,
            timezone,
            receiveLimits,
            defaultLocales,
            canReceiveBank,
            canReceiveMobile,
          } = country;

          // since this data needs to be serialisable,
          // we need to do this hacky nonsense to clean up undefined values
          // https://github.com/vercel/next.js/discussions/11209#discussioncomment-35915
          return JSON.parse(
            JSON.stringify({
              currency,
              iso2,
              name,
              send,
              receive,
              validReceiveCountriesIso2,
              payoutMethods,
              sendLimits,
              currencyDisplay,
              shortCurrencyPrefix,
              intl,
              currencyQuant,
              iso3,
              timezone,
              receiveLimits,
              defaultLocales,
              canReceiveBank,
              canReceiveMobile,
            }),
          );
        }) || [],
    };
  }

  return res;
};
