import { createContext, useContext, useMemo } from 'react';

import { CountryData } from '@/services/';

import { INITIAL_APP_CONTEXT } from './app.constants';
import { AppContextType } from './app.types';

export const AppContext = createContext<AppContextType>(INITIAL_APP_CONTEXT);

export const useAppContext = () => {
  const props = useContext(AppContext);
  const parsedProps = useMemo(() => {
    return {
      sendCountry: (props.sendCountry as unknown) as CountryData,
      receiveCountry: (props.receiveCountry as unknown) as CountryData,
      sendCountries: (props.sendCountries as unknown) as CountryData[],
      receiveCountries: (props.receiveCountries as unknown) as CountryData[],
    };
  }, [
    props.receiveCountries,
    props.receiveCountry,
    props.sendCountries,
    props.sendCountry,
  ]);
  return { ...props, ...parsedProps };
};

AppContext.displayName = 'AppContext';
