import axios, { AxiosRequestConfig } from 'axios';

import { RestResponse } from './rest.types';

export const restParser = async <T>(
  url: string,
  options?: AxiosRequestConfig,
): Promise<RestResponse<T>> => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      ...options?.headers,
    };

    const response = await axios({
      url,
      ...options,
      headers,
    });

    return {
      ok: true,
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        ok: false,
        data: undefined,
        status: error.response?.status || 500,
        error: error?.response?.data || error?.message,
      };
    }

    return {
      ok: false,
      data: undefined,
      status: 500,
      error: (error as Error)?.message,
    };
  }
};
