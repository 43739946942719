import { CountryData } from '@wr/web-ui';

import {
  CountryDataProps,
  getCurrencyCorridorSlug,
  getCurrencySlug,
} from '@/services/rest';
import {
  CexCurrencyCorridor,
  CexCurrencyCorridorData,
  CurrencyInfo,
} from '@/services/rest/currency-corridors/currency-corridor.types';

import {
  CexCorridorGroup,
  CexCountry,
  CexCurrency,
  CexCurrencyDataFragment,
  CexCurrencyFragment,
  CexCurrencyGroup,
} from './currency-data.types';

export const getCurrencyDetailsFromSlug = (slug: string) => {
  const slugParams = slug.split('-');
  const params = slugParams.reduce(
    (
      result: {
        currencyName: string[];
        currencyCode: string;
        countryCode?: string;
      },
      entry,
      index,
    ) => {
      if (index === slugParams.length - 1) {
        const [currencyCode, countryCode] = entry.split('_');
        result.currencyCode = currencyCode;

        if (countryCode) {
          result.countryCode = countryCode;
        }
      } else {
        result.currencyName.push(entry);
      }
      return result;
    },
    { currencyName: [], currencyCode: '', countryCode: '' },
  );
  return {
    currencyCode: params.currencyCode.toLowerCase(),
    currencyName: params.currencyName.join('-').toLowerCase(),
    countryCode: params.countryCode?.toLowerCase() || '',
  };
};

export const parseCountry = (
  contentfulCurrency: CexCurrencyDataFragment | null,
  slugCountryCode: string,
  corridorCurrency: CurrencyInfo,
): CountryData => {
  if (contentfulCurrency) {
    const isMatchingCountryCode =
      !slugCountryCode ||
      contentfulCurrency.countryCode?.toLowerCase() ===
        slugCountryCode.toLowerCase();
    if (isMatchingCountryCode) {
      return parseCexCurrencyAsCountry(contentfulCurrency);
    }
  }

  return {
    currency: corridorCurrency.currencyCode.toUpperCase(),
    currencyName: corridorCurrency.currencyName,
    countryCode: corridorCurrency.countryCode.toUpperCase(),
    iso2: corridorCurrency.countryCode.toLowerCase(),
    name: corridorCurrency.countryName,
  };
};

export const parseCexCurrencyAsCountry = (
  currency: CexCurrencyDataFragment,
): CountryData => ({
  currency: currency.countryCode ?? '',
  currencyName: currency.countryName ?? '',
  countryCode: currency.countryCode ?? '',
  iso2: currency.countryCode?.toLowerCase() ?? '',
  name: currency.countryName ?? '',
});

export const parseCexCountryData = (currency: CountryDataProps) =>
  ({
    currency: currency.currency,
    currencyName: currency.name,
    countryCode: currency.iso2,
    iso2: currency.iso2?.toLowerCase() ?? '',
    name: currency.name ?? '',
  } as CountryData);

export const parseCexCurrency = (
  currency:
    | CexCurrencyCorridorData['receiveCurrency']
    | CexCurrencyCorridorData['sendCurrency'],
) => {
  return {
    currencyCode: currency?.currencyCode ?? '',
    currencyName: currency?.currencyName ?? '',
    countryCode: currency?.countryCode ?? '',
    countryName: currency?.countryName ?? '',
    pageSlug: currency?.pageSlug ?? '',
  };
};

export const parseCexCountryToCexCurrency = (
  country: CexCountry,
): CexCurrency => {
  return {
    currencyCode: country.currency?.code as string,
    currencyName: country.currency?.name ?? '',
    countryCode: country.code as string,
    countryName: country.name ?? '',
    pageSlug: getCurrencySlug({
      currencyCode: (country.currency?.code as string) ?? undefined,
    }),
  };
};

export const parseCurrencyInformationToCexCurrency = (
  currencyInformation: CexCurrencyFragment,
): CexCurrency => {
  return {
    currencyCode: currencyInformation.currencyCode ?? '',
    currencyName: currencyInformation.currencyName ?? '',
    countryCode: currencyInformation.countryCode ?? '',
    countryName: currencyInformation.countryName ?? '',
    pageSlug: getCurrencySlug({
      currencyCode: currencyInformation.currencyCode ?? undefined,
    }),
  };
};

export const parseCexCorridors = (
  corridors: (CexCurrencyFragment | null)[],
) => {
  return corridors
    .map(currency => {
      if (!currency) return;
      return parseCexCurrency(currency);
    })
    .filter((currency): currency is CexCurrency => currency !== null);
};

export const groupCurrenciesByAlphabet = (
  currencies: CexCurrency[],
): CexCurrencyGroup => {
  return currencies.reduce((currencyGroup: CexCurrencyGroup, currency) => {
    const firstLetter = currency?.currencyCode?.charAt(0)?.toUpperCase() ?? '';
    if (!currencyGroup[firstLetter]) {
      currencyGroup[firstLetter] = [currency];
    } else {
      currencyGroup[firstLetter].push(currency);
    }
    return currencyGroup;
  }, {});
};

export const groupCorridorsByAlphabet = (
  corridors: CexCurrencyCorridor[],
  currencyCode: string | null | undefined,
): CexCorridorGroup => {
  return corridors.reduce((currencyGroup: CexCorridorGroup, corridor) => {
    const firstLetter =
      currencyCode === corridor.sendCurrency.currencyCode
        ? corridor.receiveCurrency.currencyCode?.charAt(0)?.toUpperCase()
        : corridor.sendCurrency.currencyCode?.charAt(0)?.toUpperCase() ?? '';
    if (!currencyGroup[firstLetter]) {
      currencyGroup[firstLetter] = [corridor];
    } else {
      currencyGroup[firstLetter].push(corridor);
    }
    return currencyGroup;
  }, {});
};

export const formatCorridors = ({
  sendCountries,
  receiveCountries,
  currencyInformation,
}: {
  sendCountries: CexCountry[];
  receiveCountries: CexCountry[];
  currencyInformation: CexCurrencyFragment;
}): CexCurrencyCorridor[] => {
  const currencyCorridors: CexCurrencyCorridor[] = [
    ...sendCountries.map(country => {
      return {
        pageSlug: getCurrencyCorridorSlug({
          sendCurrencyCode: country.currency?.code as string,
          receiveCurrencyCode: currencyInformation.currencyCode ?? '',
          cexPageSlug: currencyInformation.pageSlug ?? undefined,
        }),
        sendCurrency: parseCexCountryToCexCurrency(country),
        receiveCurrency: parseCurrencyInformationToCexCurrency(
          currencyInformation,
        ),
      };
    }),
    ...receiveCountries.map(country => {
      return {
        pageSlug: getCurrencyCorridorSlug({
          sendCurrencyCode: currencyInformation.currencyCode ?? '',
          receiveCurrencyCode: country.currency?.code as string,
          cexPageSlug: currencyInformation.pageSlug ?? undefined,
        }),
        sendCurrency: parseCurrencyInformationToCexCurrency(
          currencyInformation,
        ),
        receiveCurrency: parseCexCountryToCexCurrency(country),
      };
    }),
  ];

  return currencyCorridors;
};

export const parseCexCorridor = (corridor: CexCurrencyCorridorData) => {
  return {
    name: corridor?.name ?? '',
    pageSlug: corridor?.pageSlug ?? '',
    sendCurrency: parseCexCurrency(corridor?.sendCurrency),
    receiveCurrency: parseCexCurrency(corridor?.receiveCurrency),
  } as CexCurrencyCorridor;
};
