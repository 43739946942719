import '@wr/web-ui/src/components/pdf-viewer/pdf-viewer.annotation.css';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import {
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
  FeatureFlagData,
  NEXT_PUBLIC_OPTIMIZELY_SCRIPT_URL,
  useFeatureFlagStore,
} from '@wr/web-shared';
import { ErrorBoundary, TRUSTPILOT_WIDGET_SCRIPT_URL } from '@wr/web-ui';
import NextApp, { AppContext as NextAppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useReportWebVitals } from 'next/web-vitals';
import React, { FC, useEffect } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { sendwaveMuiTheme } from '@/components/theme/theme.styles';
import { GOOGLE_RECAPTCHA_SCRIPT_URL } from '@/constants';
import { getContentfulFeatureFlags } from '@/services/contentful/feature-flags';
import { WEB_VITAL_METRICS } from '@/services/metric/metric.constants';
import { CWVMetric } from '@/services/metric/metric.types';
import { PageProps } from '@/types';
import { logger } from '@/utils';

export type AppPropsWithLayout = AppProps<PageProps> & {
  isWebView: boolean;
  Component: {
    Layout?: FC;
  };
};

const App = (props: AppPropsWithLayout) => {
  const { Component, pageProps, router, isWebView } = props;
  const setFeatureFlags = useFeatureFlagStore(state => state.setFeatureFlags);
  const setError = useFeatureFlagStore(state => state.setError);
  const setLoading = useFeatureFlagStore(state => state.setLoading);

  useReportWebVitals(metric => {
    if (process.env.NEXT_PUBLIC_METRICS_URL) {
      const cwvMetric: CWVMetric = {
        ...metric,
        label: WEB_VITAL_METRICS,
        page: pageProps?.analyticsPageType ?? '',
      };

      const body = JSON.stringify(cwvMetric);
      const url = process.env.NEXT_PUBLIC_METRICS_URL;

      if (navigator.sendBeacon) {
        navigator.sendBeacon(url, body);
      } else {
        fetch(url, { body, method: 'POST', keepalive: true });
      }
    }
  });

  useEffect(() => {
    const populateFeatureFlags = async () => {
      setLoading(true);
      try {
        const remoteFeatureFlags = await getContentfulFeatureFlags({
          preview: false,
        });
        setFeatureFlags(remoteFeatureFlags as FeatureFlagData);
      } catch (error) {
        setError('Error fetching feature flags');
      } finally {
        setLoading(false);
      }
    };
    populateFeatureFlags();
  }, [setError, setLoading, setFeatureFlags]);
  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="theme-color"
          content={sendwaveMuiTheme.palette.primary.main}
        />
        <meta name="contentful_space" content={CONTENTFUL_SPACE_ID} />
        <meta name="contentful_environment" content={CONTENTFUL_ENVIRONMENT} />
      </Head>
      <ThemeProvider theme={sendwaveMuiTheme}>
        <CssBaseline />
        <ErrorBoundary<RichTextProps>
          onError={(err: Error, errorInfo: React.ErrorInfo) =>
            logger.error(
              {
                err,
                componentStack: errorInfo?.componentStack,
              },
              'render error',
            )
          }
          errorMessages={{
            header: 'An error occured',
          }}
          RichTextComponent={RichTextContentRenderer}
          showErrorDetails={
            process.env.NEXT_PUBLIC_SHOW_ERROR_DETAILS === 'true'
          }
        >
          <Component {...pageProps} router={router} isWebView={isWebView} />
        </ErrorBoundary>
      </ThemeProvider>

      {/* Deferring optimizely script to client to avoid hydration errors when
          content is changed between the server and before nextjs loads.
          We are assuming that the combination of a client side environment variable
          NEXT_PUBLIC_ and the use of afterInteractive will ensure that the script
          is only loaded after first party nextjs code has run to verify that the
          server and client are in sync, lest we get the following error:
          https://legacy.reactjs.org/docs/error-decoder.html/?invariant=418 */}
      <Script src={NEXT_PUBLIC_OPTIMIZELY_SCRIPT_URL} />
      <Script src={TRUSTPILOT_WIDGET_SCRIPT_URL} />
      <Script src={GOOGLE_RECAPTCHA_SCRIPT_URL} />
    </AppCacheProvider>
  );
};

App.getInitialProps = async (appContext: NextAppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  const cookies =
    (appContext.ctx.req as typeof appContext.ctx.req & {
      cookies: Record<string, string>;
    })?.cookies ?? {};

  return { ...appProps, isWebView: cookies.client === 'greenfield-webview' };
};

export default App;
