import {
  createIntlListFormat,
  getIntlMonthFormat,
} from '@wr/web-ui/src/utils/intl/intl';

import { getCurrenciesCodesAndName } from '../currency';
import { getCountryNameAndCode } from '../locale';
import { GetIntlMessagesConfig, GetIntlMessagesResult } from './messages.types';

export function getIntlMessages({
  sendCountry,
  sendCountries,
  receiveCountry,
  receiveCountries,
  payoutMethodsLabels,
  countryArticlesAndPrepositions,
  corridorPayoutMethodsIds,
  locale,
  currentDate,
  selectedPayoutMethodId,
  sendAmount,
}: GetIntlMessagesConfig): GetIntlMessagesResult {
  const payoutMethods = corridorPayoutMethodsIds
    ?.map(payoutMethodsId => payoutMethodsLabels?.[payoutMethodsId])
    .filter(Boolean);

  const monthFormat = getIntlMonthFormat(locale);
  const currentYear = currentDate?.getFullYear().toString();
  const currentMonth = monthFormat.format(currentDate);

  const [
    sendCountryCurrencyNames,
    sendCountryCurrencyCodes,
  ] = getCurrenciesCodesAndName(sendCountry, sendCountries);

  const [
    receiveCountryCurrencyNames,
    receiveCountryCurrencyCodes,
  ] = getCurrenciesCodesAndName(receiveCountry, receiveCountries);

  const _sendCountry = getCountryNameAndCode(
    sendCountry,
    countryArticlesAndPrepositions,
  );
  const _receiveCountry = getCountryNameAndCode(
    receiveCountry,
    countryArticlesAndPrepositions,
  );

  const {
    conjunctionListFormat,
    disjunctionListFormat,
    defaultListFormat,
  } = createIntlListFormat(locale);

  return {
    'currentYear': currentYear || '',
    currentMonth,
    'sendCountry.name': _sendCountry.nameAndArticle,
    'sendCountry.code': _sendCountry.code,
    'sendCountry.name.from': _sendCountry.prepositionFrom,
    'sendCountry.name.to': _sendCountry.prepositionTo,
    'sendCountry.name.in': _sendCountry.prepositionIn,

    'countryName': _receiveCountry.name,
    'receiveCountry': _receiveCountry.name,

    'receiveCountry.name': _receiveCountry.nameAndArticle,
    'receiveCountry.code': _receiveCountry.code,
    'receiveCountry.name.from': _receiveCountry.prepositionFrom,
    'receiveCountry.name.to': _receiveCountry.prepositionTo,
    'receiveCountry.name.in': _receiveCountry.prepositionIn,

    'availablePayoutMethods': defaultListFormat.format(payoutMethods),
    // Cash Pickup, Bank Transfer, and Door to Door
    'availablePayoutMethods.and': conjunctionListFormat.format(payoutMethods),
    // Cash Pickup, Bank Transfer, or Door to Door
    'availablePayoutMethods.or': disjunctionListFormat.format(payoutMethods),

    // Sterling Pound, US Dollar
    'sendCountryCurrencies.name': defaultListFormat.format(
      sendCountryCurrencyNames,
    ),
    // GBP, USD
    'sendCountryCurrencies.code': defaultListFormat.format(
      sendCountryCurrencyCodes,
    ),
    // Sterling Pound and US Dollar
    'sendCountryCurrencies.name.and': conjunctionListFormat.format(
      sendCountryCurrencyNames,
    ),
    // GBP and USD
    'sendCountryCurrencies.code.and': conjunctionListFormat.format(
      sendCountryCurrencyCodes,
    ),
    // Sterling Pound or US Dollar
    'sendCountryCurrencies.name.or': disjunctionListFormat.format(
      sendCountryCurrencyNames,
    ),
    // GBP or USD
    'sendCountryCurrencies.code.or': disjunctionListFormat.format(
      sendCountryCurrencyCodes,
    ),

    'receiveCountryCurrencies.name': defaultListFormat.format(
      receiveCountryCurrencyNames,
    ),
    'receiveCountryCurrencies.code': defaultListFormat.format(
      receiveCountryCurrencyCodes,
    ),
    'receiveCountryCurrencies.name.and': conjunctionListFormat.format(
      receiveCountryCurrencyNames,
    ),
    'receiveCountryCurrencies.code.and': conjunctionListFormat.format(
      receiveCountryCurrencyCodes,
    ),
    'receiveCountryCurrencies.name.or': disjunctionListFormat.format(
      receiveCountryCurrencyNames,
    ),
    'receiveCountryCurrencies.code.or': disjunctionListFormat.format(
      receiveCountryCurrencyCodes,
    ),
    'payoutMethod.code': selectedPayoutMethodId || '',
    'payoutMethod.name':
      (selectedPayoutMethodId &&
        payoutMethodsLabels?.[selectedPayoutMethodId]) ||
      '',
    'sendAmount': sendAmount,
    'sendCurrencyName': sendCountryCurrencyCodes[0],
    'receiveCurrencyName': receiveCountryCurrencyCodes[0],
    'sendCurrencyCode': sendCountry.currency || '',
    'receiveCurrencyCode': receiveCountry.currency || '',
  };
}
