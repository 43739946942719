import { contentfulSchema } from '@wr/web-shared';

import { contentfulClient, logger } from '@/utils';

import { RedirectsQueryResult } from './redirect.types';

export const getRedirects = async (): Promise<RedirectsQueryResult> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.RedirectsQuery>({
      query: contentfulSchema.Redirects,
      variables: {
        limit: 1000,
      },
    });

    return data?.redirectCollection?.items || [];
  } catch (error) {
    logger.error(error, `Redirects query request failed`);

    return [];
  }
};
