import { create } from 'zustand';

import { Countries } from '@/services/rest';

export type CountryData = Countries.CountryData;

type State = {
  countries?: CountryData[];
  loading: boolean;
  error?: string;
};

type Actions = {
  fetch: () => Promise<void>;
  hydrate: (countries: CountryData[]) => void;
  clear: () => void;
};

export const initialCountriesState = {
  loading: false,
  countries: undefined,
  error: undefined,
};

export const useCountriesStore = create<State & Actions>()(set => ({
  countries: initialCountriesState.countries,
  loading: initialCountriesState.loading,
  error: initialCountriesState.error,
  hydrate: countries => set({ countries }),
  clear: () => set({ ...initialCountriesState }),
  fetch: async () => {
    const defaultError = 'Could not fetch countries';
    set({ loading: true });
    try {
      const { data, ok, error } = await Countries.get();

      if (error) throw new Error(error as string);
      if (!ok) throw new Error(defaultError);

      set({ countries: data, loading: false });
    } catch (error) {
      set({
        error: (error as Error)?.message || defaultError,
        loading: false,
      });
    }
  },
}));
