import { contentfulSchema } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import {
  contentfulClient,
  getAllModuleItems,
  logger,
  Module,
  QueryOptions,
} from '@/utils';

import { CexCurrencyFragment } from './currency-data.types';
import { parseCexCurrency } from './currency-data.utils';

export async function getCurrencyList(queryArgs: QueryOptions) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyListQuery,
      contentfulSchema.CexCurrencyListQueryVariables
    >({
      query: contentfulSchema.CexCurrencyList,
      variables: {
        preview: queryArgs.preview,
        locale: queryArgs?.locale ?? '',
      },
    });

    const metadata = data?.cexCurrencyCollection?.items || [];

    const modules = await getAllModuleItems({
      modules: metadata as Module[],
      ...queryArgs,
    });

    return [...modules]
      .map(module => {
        const __typename = Object.keys(module);
        return parseCexCurrency(module[__typename[0]] as CexCurrencyFragment);
      })
      .sort((a, b) => {
        const aCurrencyCode = a?.currencyCode ?? '';
        const bCurrencyCode = b?.currencyCode ?? '';
        return aCurrencyCode.localeCompare(bCurrencyCode);
      });
  } catch (error) {
    logger.error(error, `CexCurrencyListQuery request failed`);
    return [];
  }
}

export async function getCurrencyInformationBySlug({
  slug,
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  slug: string;
}) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyBySlugQuery,
      contentfulSchema.CexCurrencyBySlugQueryVariables
    >({
      query: contentfulSchema.CexCurrencyBySlug,
      variables: {
        slug,
        preview,
        locale,
      },
    });

    if (data?.cexCurrencyCollection?.items?.length) {
      return data.cexCurrencyCollection.items?.[0];
    }

    return null;
  } catch (error) {
    logger.error(error, `CexCurrencyBySlugQuery request failed`);
    return null;
  }
}

export async function getCurrencyInformationByCode({
  currencyCode,
  currencyName,
  countryCode,
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  currencyCode: string;
  currencyName?: string;
  countryCode?: string;
}) {
  const codeVariants = [currencyCode.toLowerCase(), currencyCode.toUpperCase()];

  const countryVariants = countryCode
    ? [countryCode.toLowerCase(), countryCode.toUpperCase()]
    : [];

  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyByCodeQuery,
      contentfulSchema.CexCurrencyByCodeQueryVariables
    >({
      query: contentfulSchema.CexCurrencyByCode,
      variables: {
        code: codeVariants,
        ...(countryVariants.length > 0 && { countryCode: countryVariants }),
        locale,
        preview,
      },
    });

    if (!data?.cexCurrencyCollection?.items?.length) {
      return null;
    }

    const currency = data.cexCurrencyCollection.items?.[0];

    if (currencyName) {
      const actualCurrencyName = currency?.currencyName
        ?.toLowerCase()
        .split(' ')
        .join('-');
      if (currencyName !== actualCurrencyName) {
        return null;
      }
    }

    return currency;
  } catch (error) {
    logger.error(error, `CexCurrencyByCodeQuery request failed`);
    return null;
  }
}

export async function getCurrencySlugs({
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'>) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencySlugsQuery,
      contentfulSchema.CexCurrencySlugsQueryVariables
    >({
      query: contentfulSchema.CexCurrencySlugs,
      variables: {
        preview,
        locale,
      },
    });

    return (data?.cexCurrencyCollection?.items ?? [])
      .filter((entry): entry is CexCurrencyFragment => entry !== null)
      .map(parseCexCurrency);
  } catch (error) {
    logger.error(error, `CexCurrencySlugsQuery request failed`);
    return [];
  }
}
