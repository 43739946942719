import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

export const useAccordionToggle = (
  expandedPanel: string | undefined,
  subExpandedPanel?: string | undefined,
) => {
  const handleIcon = useCallback((index: string, panel: string | undefined) => {
    return panel !== index ? <AddIcon /> : <RemoveIcon />;
  }, []);

  const handlePanelIcon = useCallback(
    (panelIndex: string) => {
      return handleIcon(panelIndex, expandedPanel);
    },
    [handleIcon, expandedPanel],
  );

  const handleSubPanelIcon = useCallback(
    (subPanelIndex: string) => {
      return handleIcon(subPanelIndex, subExpandedPanel);
    },
    [handleIcon, subExpandedPanel],
  );

  const handleToggleAccordion = (
    setFn: Dispatch<SetStateAction<string | undefined>>,
    index: string,
  ) => {
    setFn(prev => (prev === index ? undefined : index));
  };

  return {
    handlePanelIcon,
    handleToggleAccordion,
    handleSubPanelIcon,
  };
};
