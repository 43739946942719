import { createContentfulClient, WEB_CMS_SENDWAVE } from '@wr/web-shared';

import { logger } from '.';

export const contentfulClient = createContentfulClient({
  logger,
  type: WEB_CMS_SENDWAVE,
});

const intervalInHours =
  (Number(process.env.CONTENTFUL_CLEAR_STORE_HOUR) || 24) * 60 * 60 * 1000;

setInterval(() => {
  logger.info('contentfulClient clearStore running');
  contentfulClient.clearStore();
}, intervalInHours);
