export const REGION_CODES = {
  frCa: 'CA',
  esUs: 'US',
  enUs: 'US',
  enGb: 'GB',
  enNz: 'NZ',
  enMy: 'MY',
  enSe: 'SE',
  enAu: 'AU',
  enCa: 'CA',
  daDk: 'DK',
  en: 'en',
};
