import { DocumentNode } from '@apollo/client';
import { contentfulSchema as queries } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import { contentfulClient, logger } from '@/utils';
import { Module, ModuleItem } from '@/utils/contentful';

export type ModuleWithData = { module: Module; data: ModuleItem | null };

export const getModulesData = async ({
  module,
  locale,
  preview = false,
}: {
  module: Module;
} & Pick<
  GetServerSidePropsContext,
  'preview' | 'locale'
>): Promise<ModuleWithData | null> => {
  const query = (queries as Record<string, unknown>)[
    `${module?.__typename}Query`
  ] as DocumentNode;

  if (query) {
    try {
      const { data } = await contentfulClient.query<ModuleItem>({
        query,
        variables: {
          id: module?.sys.id,
          locale,
          preview,
        },
      });

      return { module, data };
    } catch (error) {
      logger.error(error, `Module: ${module?.sys.id} query request failed`);

      return null;
    }
  } else {
    return null;
  }
};
