import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { useSmartLink } from '@wr/web-ui';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { getLinkHref, getRelAttribute } from '@/components/link/link.utils';
import { SMART_LINK_URL } from '@/constants';

import { ButtonProps } from './button.types';

export const Button: FC<ButtonProps> = ({
  component = 'button',
  children,
  id,
  'url': urlTxt,
  variant,
  size,
  color,
  name,
  className,
  classes,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  onClick,
  fullWidth,
  disabled,
  isInternal = true,
  tabIndex,
  noFollow,
  openInNewTab,
  type,
}) => {
  const { locale } = useRouter();
  const smartLink = useSmartLink(urlTxt || '', SMART_LINK_URL);
  const url = urlTxt;
  const href = getLinkHref(url, locale);

  const buttonProps = {
    id,
    'name': name || undefined,
    'aria-label': ariaLabel || undefined,
    component,
    'rel':
      component !== 'a'
        ? getRelAttribute(noFollow || false, openInNewTab || false, href)
        : undefined,
    className,
    classes,
    'data-testid': dataTestId,
    onClick,
    disabled,
    fullWidth,
    'color': (color as MuiButtonProps['color']) || undefined,
    'variant': (variant as MuiButtonProps['variant']) || undefined,
    'size': (size as MuiButtonProps['size']) || undefined,
    tabIndex,
    type,
  };

  if (url && (isInternal || isInternal === null)) {
    return (
      <NextLink href={smartLink ?? href} passHref legacyBehavior>
        <MuiButton {...buttonProps}>{children}</MuiButton>
      </NextLink>
    );
  }

  return (
    <MuiButton href={smartLink ?? href} {...buttonProps}>
      {children}
    </MuiButton>
  );
};
