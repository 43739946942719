import { contentfulSchema } from '@wr/web-shared';
import type { GetServerSidePropsContext } from 'next';

import { contentfulClient, logger } from '@/utils';

export const getContentfulCountries = async (): Promise<contentfulSchema.GetCountriesQuery> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.GetCountriesQuery>({
      query: contentfulSchema.GetCountries,
    });

    return data;
  } catch (error) {
    logger.error(error, `GetCountriesQuery request failed`);

    throw {
      name: 'GetCountriesQuery',
      message: error,
    };
  }
};

export const getContentfulCountriesArticlesAndPrepositions = async ({
  locale,
  preview,
  countryCodes,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  countryCodes: string[] | null;
}): Promise<contentfulSchema.CountryArticlesAndPrepositionsQuery> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.CountryArticlesAndPrepositionsQuery>(
      {
        query: contentfulSchema.CountryArticlesAndPrepositions,
        variables: {
          locale,
          preview,
          countryCodes,
        },
      },
    );

    return data;
  } catch (error) {
    logger.error(error, `CountryArticlesAndPrepositionsQuery request failed`);

    throw {
      name: 'CountryArticlesAndPrepositionsQuery',
      message: error,
    };
  }
};

export const getReviewsByCountryCode = async ({
  receiveCountry,
  locale,
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  receiveCountry: string;
}): Promise<contentfulSchema.ReviewsCardByReceiveCountryQuery> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.ReviewsCardByReceiveCountryQuery>(
      {
        query: contentfulSchema.ReviewsCardByReceiveCountry,
        variables: {
          receiveCountry,
          locale,
          preview,
        },
      },
    );

    return data;
  } catch (error) {
    logger.error(error, `ReviewsByCountryCodeQuery request failed`);

    throw {
      name: 'ReviewsByCountryCodeQuery',
      message: error,
    };
  }
};
