import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles<Theme>(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    paddingTop: '2.5rem',
    maxWidth: '26.875rem',
  },
  text: {
    marginBottom: '1.25rem',
  },
  stackTrace: {
    fontSize: '0.55rem',
    position: 'relative',
    // our central column layout is too narrow for stack trace, so we need to move it to the left
    left: 'calc(7rem - 25vw)',
  },
}));

export default useStyles;
