import { create } from 'zustand';

import { Pricing } from '@/services/rest';

type PricingSegmentsData = Pricing.PricingSegmentsData;
type PricingSegmentsParams = Pricing.PricingSegmentsParams;

type State = {
  pricingSegments?: PricingSegmentsData;
  loading: boolean;
  error?: string;
};

type Actions = {
  fetch: (params: PricingSegmentsParams, locale?: string) => Promise<void>;
  hydrate: (pricing: PricingSegmentsData) => void;
  clear: () => void;
};

export const initialPricingSegmentsState = {
  loading: false,
  pricingSegments: undefined,
  error: undefined,
};

export const usePricingSegmentsStore = create<State & Actions>()(set => ({
  pricingSegments: initialPricingSegmentsState.pricingSegments,
  loading: initialPricingSegmentsState.loading,
  error: initialPricingSegmentsState.error,
  hydrate: pricingSegments => set({ pricingSegments }),
  clear: () => set({ pricingSegments: undefined }),
  fetch: async (params, locale) => {
    const defaultError = 'Could not fetch pricing segments';
    set({ loading: true });
    try {
      const { data, ok, error } = await Pricing.getSegments(params, locale);

      if (error) throw new Error(error as string);
      if (!ok) throw new Error(defaultError);

      set({ pricingSegments: data, loading: false });
    } catch (error) {
      set({
        error: (error as Error)?.message || defaultError,
        loading: false,
      });
    }
  },
}));
