import { FaqStoreState } from './faq.store';
import { Faq, FaqCountry } from './faq.types';

export const filterByCountry = (selectedCountry?: string, faqs?: Faq[]) => {
  if (!selectedCountry || !faqs) return [];

  return faqs.filter(faq =>
    faq.countries.some(
      country =>
        country.code?.toLocaleLowerCase() === selectedCountry.toLowerCase(),
    ),
  );
};

export const hydrateFaqState = ({
  faqItems,
  faqCountries,
  receiveCountry,
}: {
  faqItems?: Faq[];
  faqCountries?: FaqCountry[];
  receiveCountry?: string;
}): FaqStoreState => {
  const countryFaqItems = filterByCountry(receiveCountry, faqItems);
  return {
    _allFaqItems: faqItems ?? [],
    faqCountries,
    searchTerm: undefined,
    selectedCountry: receiveCountry ?? (faqCountries?.[0]?.code as string),
    faqItems: countryFaqItems,
  };
};
