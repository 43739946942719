import { Typography } from '@mui/material';
import React from 'react';

import { ErrorDetails } from './error-details.component';
import useStyles from './generic-error.styles';
import { GenericErrorProps } from './generic-error.types';

export function GenericError<TRichText>({
  messages,
  RichTextComponent,
  error,
  showErrorDetails,
}: GenericErrorProps<TRichText>) {
  const classes = useStyles();

  const { header, subDescription, description } = messages;

  return (
    <div className={classes.main}>
      <Typography
        data-testid="header"
        component="h1"
        variant="h2"
        align="center"
        className={classes.text}
      >
        {header}
      </Typography>
      {description && (
        <RichTextComponent
          {...description}
          className={classes.text}
          options={{
            variant: 'h6',
            align: 'center',
          }}
        />
      )}
      {subDescription && (
        <RichTextComponent
          {...subDescription}
          className={classes.text}
          options={{
            align: 'center',
            variant: 'body2',
          }}
        />
      )}

      {showErrorDetails && error && (
        <ErrorDetails error={error} stackTraceClass={classes.stackTrace} />
      )}
    </div>
  );
}
