/* eslint-disable max-len */
import { lighten } from '@mui/material';
import { colors as defaultColors } from '@wr/web-ui';

const sendwaveYellow = '#FFEC00';
const sendwaveYellowLight = '#FFF799';
const sendwaveOrange = '#F9A31A';
const sendwaveGray = '#F7F7F7';
const sendwaveBrown = '#3C0F19';
const sendwaveTeal = '#16889B';
const sendwaveTealLight = '#DBF8FF';
const sendwaveBlack = '#0D0B01';

export const colors = {
  ...defaultColors,
  sendwaveYellow,
  sendwaveYellowLight,
  sendwaveOrange,
  sendwaveGray,
  sendwaveBrown,
  sendwaveTeal,
  sendwaveTealLight,
  sendwaveBlack,
  sendwaveBlackLight: lighten(sendwaveBlack, 0.95),
};
