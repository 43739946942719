import { parseCexCountryToCexCurrency } from '@/services/contentful';

import { get } from '../countries.service';
import { CountryData, CountryDataProps } from '../country.utils';
import {
  CexCurrencyCorridor,
  CorridorResponse,
  SlugParams,
} from './currency-corridor.types';
import {
  adaptCountryToCexCountry,
  filterReceiveCountries,
  filterSendCountries,
  generateCorridorResponse,
} from './currency-corridors.utils';

export const getAllCountries = async (
  locale?: string,
): Promise<CountryDataProps[] | undefined> => {
  const response = await get(locale);

  if (response.ok) {
    return response.data;
  }

  return [];
};

export const getCorridorsBySendCountry = async (locale?: string) => {
  const countries = await getAllCountries(locale);
  return filterSendCountries(countries as CountryData[]);
};

export const getCorridorsByReceiveCountry = async (locale?: string) => {
  const countries = await getAllCountries(locale);
  return filterReceiveCountries(countries as CountryData[]);
};

export const getCurrenciesPageCorridors = async ({
  countryCode,
  locale,
}: {
  countryCode: string;
  locale?: string;
}): Promise<CorridorResponse[]> => {
  const sendCountryCorridors = await getCorridorsBySendCountry(locale);
  const receiveCountryCorridors = await getCorridorsByReceiveCountry(locale);

  const sendCountry = sendCountryCorridors[countryCode];
  if (sendCountry) {
    return sendCountry.receiveCountries.map(receiveCountry =>
      generateCorridorResponse(sendCountry, receiveCountry),
    );
  }

  const receiveCountry = receiveCountryCorridors[countryCode];
  if (receiveCountry) {
    return receiveCountry.sendCountries.map(sendCountry =>
      generateCorridorResponse(sendCountry, receiveCountry),
    );
  }

  return [];
};

export const getCurrencyCorridors = async ({
  sendCountryCode,
  receiveCountryCode,
  sendCurrencyCode,
  receiveCurrencyCode,
  locale,
}: Omit<SlugParams, 'calculationAmount'> & {
  locale?: string;
}): Promise<CorridorResponse> => {
  const activeCorridors = await getCorridorsBySendCountry(locale);
  const sendCountry = activeCorridors[sendCountryCode.toLowerCase()];

  if (!sendCountry) {
    throw new Error(`Send country not found for code: ${sendCountryCode}`);
  }

  const receiveCountry = sendCountry.receiveCountries.find(
    (country: CountryData) =>
      country.iso2.toLowerCase() === receiveCountryCode.toLowerCase() &&
      country.currency === receiveCurrencyCode.toUpperCase(),
  );

  if (!receiveCountry) {
    throw new Error(
      `Receive country not found for code: ${receiveCountryCode} with currency: ${receiveCurrencyCode}`,
    );
  }

  return generateCorridorResponse(
    sendCountry,
    receiveCountry,
    sendCurrencyCode,
    receiveCurrencyCode,
  );
};

export const getCexCorridors = async (): Promise<CexCurrencyCorridor[]> => {
  try {
    const currencyCorridors = await getCorridorSlugs();

    return currencyCorridors
      .map(corridor => {
        return {
          name: `${corridor.sendCurrency.currencyCode}-${corridor.receiveCurrency.currencyCode}`.toLowerCase(),
          pageSlug: corridor.pageSlug,
          sendCurrency: corridor.sendCurrency,
          receiveCurrency: corridor.receiveCurrency,
        };
      })
      .sort((a, b) => {
        const aCurrencyCode = a.receiveCurrency?.currencyCode ?? '';
        const bCurrencyCode = b.receiveCurrency?.currencyCode ?? '';
        return aCurrencyCode.localeCompare(bCurrencyCode);
      });
  } catch (error) {
    return [];
  }
};

export const getCorridorSlugs = async () => {
  const currencyCorridors = [];

  const sendCountries = await getCorridorsBySendCountry();

  for (const sendCountryCode in sendCountries) {
    const sendCountry = sendCountries[sendCountryCode];

    if (!sendCountry.iso2) {
      return [];
    }

    for (const receiveCountry of sendCountry.receiveCountries) {
      const adaptedSendCountry = adaptCountryToCexCountry(sendCountry);
      const adaptedReceiveCountry = adaptCountryToCexCountry(receiveCountry);

      currencyCorridors.push({
        pageSlug: `currency-converter/${sendCountry.currency}_${sendCountry.iso2}-${receiveCountry.currency}_${receiveCountry.iso2}`.toLowerCase(),
        sendCurrency: parseCexCountryToCexCurrency(adaptedSendCountry),
        receiveCurrency: parseCexCountryToCexCurrency(adaptedReceiveCountry),
      });
    }
  }

  return currencyCorridors;
};
