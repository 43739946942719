import { getModulesData, ModuleWithData } from '@/services';

import {
  GetAllModuleItemsParams,
  Module,
  ModuleItem,
  QueryOptions,
} from './modules.types';

const isModuleWithItems = (
  value: unknown,
): value is Omit<Module, 'items'> & { items: Module[] } =>
  Boolean(value && typeof value === 'object' && 'items' in value);

const doesItNeedToFetchItemData = (value: unknown) =>
  Object.keys((value as Module[])?.[0] || {})
    .sort()
    .join() === '__typename,sys';

export const getChildItems = async ({
  module,
  moduleItem,
  ...queryArgs
}: {
  module: Module;
  moduleItem: Module;
} & QueryOptions): Promise<ModuleWithData> => {
  const itemDetails: ModuleItem = {};

  for (const [moduleItemKey, moduleItemValue] of Object.entries(
    moduleItem || {},
  )) {
    if (isModuleWithItems(moduleItemValue)) {
      const moduleItemValueItems = moduleItemValue.items.filter(Boolean);
      if (doesItNeedToFetchItemData(moduleItemValueItems)) {
        const itemsData = await getAllModuleItems({
          modules: moduleItemValueItems,
          ...queryArgs,
        });

        itemDetails[moduleItemKey] = {
          items: itemsData?.flatMap(
            internalItem =>
              internalItem[`${moduleItemValueItems?.[0]?.__typename}`],
          ),
        };
      } else {
        itemDetails[moduleItemKey] = moduleItemValue;
      }
    } else {
      itemDetails[moduleItemKey] = moduleItemValue;
    }
  }

  return { module, data: itemDetails };
};

function resolve({ modules, ...queryArgs }: GetAllModuleItemsParams) {
  return modules?.length
    ? Promise.all(
        modules.map(module => getModulesData({ module, ...queryArgs })),
      )
    : Promise.resolve([]);
}

export const getAllModuleItems = async ({
  modules,
  ...queryArgs
}: GetAllModuleItemsParams): Promise<ModuleItem[]> => {
  let result: ModuleItem[] = [];

  const resolvedModules = await resolve({
    modules,
    ...queryArgs,
  });

  const modulesWithData: ModuleWithData[] = resolvedModules.filter(
    (m): m is ModuleWithData => m !== null,
  );

  for (const { module, data } of modulesWithData || []) {
    if (module !== null) {
      const moduleData = data?.[module.__typename];
      if (isModuleWithItems(moduleData)) {
        const childItemPromises = moduleData.items.map(moduleItem => {
          return getChildItems({
            module,
            moduleItem,
            ...queryArgs,
          });
        });
        const childItemResult = await Promise.all(childItemPromises);
        result = [
          ...result,
          ...childItemResult.map(item => {
            return { [item.module?.__typename || '']: item.data };
          }),
        ];
      } else if (data !== null) {
        result.push(data);
      }
    }
  }

  return result;
};
