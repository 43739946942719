import { AppContextProviderShared, appContextSharedMock } from '@wr/web-ui';
import React from 'react';

import { INITIAL_APP_CONTEXT } from './app.constants';
import { AppContextProvider } from './app.provider';
import { AppContextProviderType, MockProviderProps } from './app.types';

export const appContextMock: AppContextProviderType = {
  ...INITIAL_APP_CONTEXT,
  placeholder: '',
};

export const MockedProvider: React.FC<MockProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <AppContextProviderShared {...appContextSharedMock}>
      <AppContextProvider {...{ ...appContextMock, ...props }}>
        {children}
      </AppContextProvider>
    </AppContextProviderShared>
  );
};
