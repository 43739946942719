import { makeStyles } from '@mui/styles';

import { TextWithColorStyleProps } from './text-with-color.types';

export const useStyles = makeStyles({
  textColor: (props: TextWithColorStyleProps) => ({
    '& *': {
      color: `${props.color} !important`,
    },
  }),
});
