export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BetterWayTopRightModule: [
      'Calculator',
      'ExchangeCalculator',
      'Image',
      'Search',
      'SendToCountryModule',
    ],
    CalculatorPageModulesItem: [
      'BetterWayTop',
      'Correspondents',
      'PromotionalBanner',
      'PromotionalBannerDisclaimer',
    ],
    Entry: [
      'Accordion',
      'AccordionItem',
      'AppBanner',
      'AppBannerSection',
      'BetterWayTop',
      'BodyText',
      'Calculator',
      'CalculatorAppLeadingLinks',
      'CalculatorPage',
      'Card',
      'Cards',
      'CexCurrency',
      'CexCurrencyCorridor',
      'CexRateTable',
      'CexSearch',
      'ComparisonTable',
      'ComparisonTableCell',
      'ComparisonTableRow',
      'ContainedBanner',
      'Correspondents',
      'CountriesGrid',
      'Country',
      'ExchangeCalculator',
      'FaqHero',
      'FaqItem',
      'FaqModule',
      'FaqModuleItem',
      'FaqPopularQuestions',
      'FaqSection',
      'FaqTitleAndDescription',
      'FaqTopic',
      'FaqTopicsModule',
      'FaqTopicsModuleItems',
      'FeatureFlag',
      'FeatureFlags',
      'Footer',
      'FooterNavigationGroup',
      'FooterNavigationGroupExtras',
      'FullWidthBanner',
      'GenericErrorPage',
      'HeroSlot',
      'HeroTextVariation',
      'IconWithText',
      'Image',
      'ImageGrid',
      'ImageGridItem',
      'InfoCards',
      'LayoutFooter',
      'LayoutHeader',
      'LeadershipTeam',
      'LeadershipTeamEntry',
      'LegalMessaging',
      'Link',
      'LinkGroup',
      'ListOfCountries',
      'Messages',
      'MobileAppLeadingLinks',
      'MobileAppQrCode',
      'NestedAccordion',
      'NestedAccordionItems',
      'NestedAccordionTitleAndDescription',
      'NotFoundContent',
      'NotFoundErrorPage',
      'Page',
      'PageSection',
      'PayoutMethod',
      'Person',
      'PhotoCard',
      'PopularCountries',
      'PricingCalculator',
      'PromoApplicability',
      'PromoMessage',
      'PromotionalBanner',
      'PromotionalBannerDisclaimer',
      'Redirect',
      'RegionCode',
      'ReviewsCard',
      'ReviewsSection',
      'ReviewsTimeDictionary',
      'RobotsConfiguration',
      'Search',
      'SendToCountryModule',
      'SeoContactPoint',
      'SeoOrganizationStructuredData',
      'SeoSameAs',
      'Sitemap',
      'Step',
      'StepByStep',
      'TextCard',
      'TextMeTheAppModule',
      'TextSection',
      'TextSectionV2',
      'TextWithColor',
      'TrustpilotWidget',
      'Video',
      'WebForm',
      'WhyChoose',
      'WhyChooseReason',
    ],
    LayoutHeaderLoggedInMenuItemsItem: ['Link', 'LinkGroup'],
    LayoutHeaderNotLoggedInMenuItemsItem: ['Link', 'LinkGroup'],
    LayoutHeaderReferFriendLink: ['Link'],
    LayoutHeaderUserDropdownMenuItemsItem: ['Link'],
    PageSectionModulesItem: [
      'BodyText',
      'ComparisonTable',
      'IconWithText',
      'StepByStep',
      'TextWithColor',
      'Video',
      'WhyChoose',
    ],
    ResourceLink: [
      'AccordionItemContentResourcesBlock',
      'AccordionItemContentResourcesHyperlink',
      'AccordionItemContentResourcesInline',
      'AppBannerSectionContentResourcesBlock',
      'AppBannerSectionContentResourcesHyperlink',
      'AppBannerSectionContentResourcesInline',
      'BetterWayTopHeadingResourcesBlock',
      'BetterWayTopHeadingResourcesHyperlink',
      'BetterWayTopHeadingResourcesInline',
      'BetterWayTopSubheadingResourcesBlock',
      'BetterWayTopSubheadingResourcesHyperlink',
      'BetterWayTopSubheadingResourcesInline',
      'BodyTextContentResourcesBlock',
      'BodyTextContentResourcesHyperlink',
      'BodyTextContentResourcesInline',
      'BodyTextContentTwoResourcesBlock',
      'BodyTextContentTwoResourcesHyperlink',
      'BodyTextContentTwoResourcesInline',
      'BodyTextHeadingResourcesBlock',
      'BodyTextHeadingResourcesHyperlink',
      'BodyTextHeadingResourcesInline',
      'CexCurrencyDescriptionResourcesBlock',
      'CexCurrencyDescriptionResourcesHyperlink',
      'CexCurrencyDescriptionResourcesInline',
      'CexCurrencyFaqContentResourcesBlock',
      'CexCurrencyFaqContentResourcesHyperlink',
      'CexCurrencyFaqContentResourcesInline',
      'CexRateTableTitleResourcesBlock',
      'CexRateTableTitleResourcesHyperlink',
      'CexRateTableTitleResourcesInline',
      'CexSearchTitleTemplateResourcesBlock',
      'CexSearchTitleTemplateResourcesHyperlink',
      'CexSearchTitleTemplateResourcesInline',
      'ComparisonTableContentResourcesBlock',
      'ComparisonTableContentResourcesHyperlink',
      'ComparisonTableContentResourcesInline',
      'ContainedBannerTextResourcesBlock',
      'ContainedBannerTextResourcesHyperlink',
      'ContainedBannerTextResourcesInline',
      'FaqModuleItemDescriptionResourcesBlock',
      'FaqModuleItemDescriptionResourcesHyperlink',
      'FaqModuleItemDescriptionResourcesInline',
      'FaqTitleAndDescriptionContentResourcesBlock',
      'FaqTitleAndDescriptionContentResourcesHyperlink',
      'FaqTitleAndDescriptionContentResourcesInline',
      'FullWidthBannerTextResourcesBlock',
      'FullWidthBannerTextResourcesHyperlink',
      'FullWidthBannerTextResourcesInline',
      'GenericErrorPageDescriptionResourcesBlock',
      'GenericErrorPageDescriptionResourcesHyperlink',
      'GenericErrorPageDescriptionResourcesInline',
      'GenericErrorPageSubDescriptionResourcesBlock',
      'GenericErrorPageSubDescriptionResourcesHyperlink',
      'GenericErrorPageSubDescriptionResourcesInline',
      'ImageGridContentResourcesBlock',
      'ImageGridContentResourcesHyperlink',
      'ImageGridContentResourcesInline',
      'LegalMessagingContentResourcesBlock',
      'LegalMessagingContentResourcesHyperlink',
      'LegalMessagingContentResourcesInline',
      'NestedAccordionTitleAndDescriptionContentResourcesBlock',
      'NestedAccordionTitleAndDescriptionContentResourcesHyperlink',
      'NestedAccordionTitleAndDescriptionContentResourcesInline',
      'NotFoundContentMessageResourcesBlock',
      'NotFoundContentMessageResourcesHyperlink',
      'NotFoundContentMessageResourcesInline',
      'NotFoundErrorPageDescriptionResourcesBlock',
      'NotFoundErrorPageDescriptionResourcesHyperlink',
      'NotFoundErrorPageDescriptionResourcesInline',
      'NotFoundErrorPageSubDescriptionResourcesBlock',
      'NotFoundErrorPageSubDescriptionResourcesHyperlink',
      'NotFoundErrorPageSubDescriptionResourcesInline',
      'PageContentResourcesBlock',
      'PageContentResourcesHyperlink',
      'PageContentResourcesInline',
      'PromoMessageMessageResourcesBlock',
      'PromoMessageMessageResourcesHyperlink',
      'PromoMessageMessageResourcesInline',
      'PromotionalBannerDescriptionResourcesBlock',
      'PromotionalBannerDescriptionResourcesHyperlink',
      'PromotionalBannerDescriptionResourcesInline',
      'SendToCountryModuleDescriptionResourcesBlock',
      'SendToCountryModuleDescriptionResourcesHyperlink',
      'SendToCountryModuleDescriptionResourcesInline',
      'StepDescriptionResourcesBlock',
      'StepDescriptionResourcesHyperlink',
      'StepDescriptionResourcesInline',
      'TextCardContentResourcesBlock',
      'TextCardContentResourcesHyperlink',
      'TextCardContentResourcesInline',
      'TextSectionContentResourcesBlock',
      'TextSectionContentResourcesHyperlink',
      'TextSectionContentResourcesInline',
      'TextSectionV2ContentResourcesBlock',
      'TextSectionV2ContentResourcesHyperlink',
      'TextSectionV2ContentResourcesInline',
      'TextWithColorTextResourcesBlock',
      'TextWithColorTextResourcesHyperlink',
      'TextWithColorTextResourcesInline',
      'VideoContentResourcesBlock',
      'VideoContentResourcesHyperlink',
      'VideoContentResourcesInline',
      'WebFormDescriptionResourcesBlock',
      'WebFormDescriptionResourcesHyperlink',
      'WebFormDescriptionResourcesInline',
    ],
    _Node: [
      'Accordion',
      'AccordionItem',
      'AppBanner',
      'AppBannerSection',
      'BetterWayTop',
      'BodyText',
      'Calculator',
      'CalculatorAppLeadingLinks',
      'CalculatorPage',
      'Card',
      'Cards',
      'CexCurrency',
      'CexCurrencyCorridor',
      'CexRateTable',
      'CexSearch',
      'ComparisonTable',
      'ComparisonTableCell',
      'ComparisonTableRow',
      'ContainedBanner',
      'Correspondents',
      'CountriesGrid',
      'Country',
      'ExchangeCalculator',
      'FaqHero',
      'FaqItem',
      'FaqModule',
      'FaqModuleItem',
      'FaqPopularQuestions',
      'FaqSection',
      'FaqTitleAndDescription',
      'FaqTopic',
      'FaqTopicsModule',
      'FaqTopicsModuleItems',
      'FeatureFlag',
      'FeatureFlags',
      'Footer',
      'FooterNavigationGroup',
      'FooterNavigationGroupExtras',
      'FullWidthBanner',
      'GenericErrorPage',
      'HeroSlot',
      'HeroTextVariation',
      'IconWithText',
      'Image',
      'ImageGrid',
      'ImageGridItem',
      'InfoCards',
      'LayoutFooter',
      'LayoutHeader',
      'LeadershipTeam',
      'LeadershipTeamEntry',
      'LegalMessaging',
      'Link',
      'LinkGroup',
      'ListOfCountries',
      'Messages',
      'MobileAppLeadingLinks',
      'MobileAppQrCode',
      'NestedAccordion',
      'NestedAccordionItems',
      'NestedAccordionTitleAndDescription',
      'NotFoundContent',
      'NotFoundErrorPage',
      'Page',
      'PageSection',
      'PayoutMethod',
      'Person',
      'PhotoCard',
      'PopularCountries',
      'PricingCalculator',
      'PromoApplicability',
      'PromoMessage',
      'PromotionalBanner',
      'PromotionalBannerDisclaimer',
      'Redirect',
      'RegionCode',
      'ReviewsCard',
      'ReviewsSection',
      'ReviewsTimeDictionary',
      'RobotsConfiguration',
      'Search',
      'SendToCountryModule',
      'SeoContactPoint',
      'SeoOrganizationStructuredData',
      'SeoSameAs',
      'Sitemap',
      'Step',
      'StepByStep',
      'TextCard',
      'TextMeTheAppModule',
      'TextSection',
      'TextSectionV2',
      'TextWithColor',
      'TrustpilotWidget',
      'Video',
      'WebForm',
      'WhyChoose',
      'WhyChooseReason',
    ],
  },
};
export default result;
