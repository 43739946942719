import { contentfulSchema } from '@wr/web-shared';

import { contentfulClient, logger } from '@/utils';

import { RegionCodeQueryResult } from './region-code.types';

export const getRegionCode = async (): Promise<RegionCodeQueryResult> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.RegionCodeQuery>({
      query: contentfulSchema.RegionCode,
      variables: {
        limit: 100,
      },
    });

    return data?.regionCodeCollection?.items || [];
  } catch (error) {
    logger.error(error, `Region code query request failed`);

    return [];
  }
};
