import { create } from 'zustand';

import { Faq, FaqCountry } from './faq.types';
import { filterByCountry } from './faq.utils';

export * from './faq.types';
export * from './faq.utils';

export type FaqStoreState = {
  _allFaqItems: Faq[];
  faqCountries?: FaqCountry[];
  searchTerm?: string;
  selectedCountry?: string;
  faqItems: Faq[];
};

type Actions = {
  setSearchTerm: (searchTerm: string) => void;
  clearFilters: () => void;
  setSelectedCountry: (selectedCountry: string) => void;
};

export const initialFaqState = {
  _allFaqItems: [],
  faqCountries: undefined,
  searchTerm: undefined,
  selectedCountry: undefined,
  faqItems: [],
};

export const useFaqsStore = create<FaqStoreState & Actions>()(set => ({
  _allFaqItems: initialFaqState._allFaqItems,
  faqCountries: initialFaqState.faqCountries,
  searchTerm: initialFaqState.searchTerm,
  selectedCountry: initialFaqState.selectedCountry,
  faqItems: initialFaqState.faqItems,
  setSearchTerm: async (searchTerm: string) => {
    if (!searchTerm) {
      set({
        searchTerm: undefined,
      });

      return;
    }

    set({
      searchTerm,
    });
  },
  clearFilters: () => {
    set(() => ({
      searchTerm: undefined,
      faqSearchResults: undefined,
    }));
  },
  setSelectedCountry: (selectedCountry: string) => {
    if (!selectedCountry) {
      set(state => ({
        selectedCountry: state.faqCountries?.[0]?.code as string,
        faqItems: filterByCountry(
          (state.faqCountries?.[0]?.code as string) ?? '',
          state._allFaqItems,
        ),
      }));

      return;
    }

    set(state => {
      const faqItems = filterByCountry(selectedCountry, state._allFaqItems);
      return {
        selectedCountry,
        searchTerm: undefined,
        faqItems,
      };
    });
  },
}));
