import { isClient } from '@wr/web-shared';
import memoize from 'lodash.memoize';
import { useRef } from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';

export const useStoreSync = <T>(
  useStore: UseBoundStore<StoreApi<T>>,
  state: T,
): UseBoundStore<StoreApi<T>> => {
  const unsynced = useRef(true);
  const serverStore = memoize(create<T>(() => state));

  if (unsynced.current) {
    useStore.setState(state);
    unsynced.current = false;
  }

  return isClient() ? useStore : serverStore;
};
