import React, { Component, createContext, ErrorInfo, ReactNode } from 'react';

import { GenericError, GenericErrorMessages } from '..';

interface Props<TRichText> {
  children?: ReactNode;
  errorMessages: GenericErrorMessages<TRichText>;
  RichTextComponent: React.FC<TRichText>;
  onError: (error: Error, errorInfo: React.ErrorInfo) => void;
  showErrorDetails: boolean;
}

interface State {
  error?: Error;
}

export const ErrorBoundaryContext = createContext<(error: Error) => void>(
  () => {},
);
ErrorBoundaryContext.displayName = 'ErrorBoundaryContext';

export class ErrorBoundary<TRichText> extends Component<
  Props<TRichText>,
  State
> {
  state: State = {};

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onError(error, errorInfo);
  }

  showError = (error: Error) => {
    this.setState({ error });
  };

  render() {
    if (this.state.error) {
      const { RichTextComponent, errorMessages, showErrorDetails } = this.props;
      const { error } = this.state;
      return (
        <GenericError
          messages={errorMessages}
          RichTextComponent={RichTextComponent}
          error={error}
          showErrorDetails={showErrorDetails}
        />
      );
    }

    return (
      <ErrorBoundaryContext.Provider value={this.showError}>
        {this.props.children}
      </ErrorBoundaryContext.Provider>
    );
  }
}
