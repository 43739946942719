import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import { getSendCountryCodeFromLocale } from '@wr/web-ui/src/utils/intl/intl';
import { GetServerSidePropsContext } from 'next';

import { DEFAULT_LOCATION, INITIAL_SEND_AMOUNT } from '@/constants';
import {
  Countries,
  getCexCorridors,
  getPage,
  getPageData,
  SlugParams,
} from '@/services';
import {
  getCurrencyList,
  selectOrganizationMarkup,
} from '@/services/contentful';
import { getRedirects } from '@/services/contentful/redirects';
import { getLinksWithoutRedirects } from '@/services/contentful/redirects/redirect.utils';
import { getRegionCode } from '@/services/contentful/region-code';

import { getBreadcrumbs } from '../breadcrumbs.utils';
import { getAllModuleItems } from '../contentful';
import { interpolate } from '../interpolate';
import { logger } from '../logger';

type CurrencyConverterPageDataArgs = GetServerSidePropsContext & {
  slugParams?: SlugParams;
  resolvedUrl?: string | undefined;
  overrideProps?: {
    slug: string;
  };
  defaultLocale?: string;
  corridorSendCountryCode?: string;
  sendCurrencyName?: string;
  receiveCurrencyName?: string;
  sendCountryName?: string;
  receiveCountryName?: string;
  sendCurrencyCode?: string;
  receiveCurrencyCode?: string;
  receiveCountryCode?: string;
};

export const getCurrencyConverterPageData = async ({
  locale,
  slugParams,
  resolvedUrl,
  overrideProps,
  preview,
  defaultLocale,
  corridorSendCountryCode,
  sendCurrencyName = '',
  receiveCurrencyName = '',
  sendCountryName = '',
  receiveCountryName = '',
  sendCurrencyCode = '',
  receiveCurrencyCode = '',
  receiveCountryCode,
}: CurrencyConverterPageDataArgs) => {
  try {
    let pageType;
    const region = (() => {
      if (locale && locale !== defaultLocale) return locale;
      return DEFAULT_LANGUAGE;
    })();

    const sendCountryCode =
      corridorSendCountryCode ||
      slugParams?.sendCurrencyCode ||
      getSendCountryCodeFromLocale(locale) ||
      DEFAULT_LOCATION;

    const [currentUrl] = resolvedUrl.split('?');
    const resolvedSlug = currentUrl
      .replace('/', '')
      .split('/')
      .filter(param => !!param);

    const slug = overrideProps?.slug ?? resolvedSlug.join('/');

    const page = await getPage({
      slug,
      locale: region,
      region,
      preview,
    });

    if (!page) {
      logger.error({ slug }, 'Page was not found in contentful');

      return {
        notFound: true,
      };
    }

    const [
      pageData,
      modules,
      { data: countries },
      pageRedirects,
    ] = await Promise.all([
      getPageData({
        pageId: page.sys.id,
        locale: region,
        slug: page?.slug || '',
        analyticsPageType: pageType ?? '',
        preview,
        sendCountryCode,
      }),
      getAllModuleItems({
        locale: region,
        modules: page?.modulesCollection?.items,
        preview,
      }),
      Countries.get(),
      getRedirects(),
    ]);

    const breadcrumbs = getBreadcrumbs(pageData);

    const sendAmount = (
      slugParams?.calculationAmount || INITIAL_SEND_AMOUNT
    ).toString();

    const interpolatedBreadcrumbs = breadcrumbs?.map(breadcrumb => {
      return {
        ...breadcrumb,
        name: breadcrumb.name
          ? interpolate(breadcrumb.name, {
              sendAmount,
              sendCurrencyName,
              sendCountryName,
              receiveCurrencyName,
              receiveCountryName,
            })
          : breadcrumb.name,
      };
    });

    const regionSlug = page.regions?.map(region => ({
      slug: slug ? `${region}/${slug}` : region,
      title: region || '',
    }));

    const pageLinksWithRegionsAndSlugs = getLinksWithoutRedirects(
      regionSlug,
      pageRedirects,
    );

    const regions =
      page.regions?.filter((region): region is string => Boolean(region)) || [];

    const organizationSchema = selectOrganizationMarkup(pageData, page);

    const regionCode = await getRegionCode();

    const title = interpolate(page.title, {
      sendAmount,
      sendCurrencyName,
      receiveCurrencyName,
      sendCountryName: sendCountryName,
      receiveCountryName: receiveCountryName,
    });

    const description = interpolate(page.description, {
      sendCurrencyName,
      receiveCurrencyName,
      sendCurrencyCode,
      receiveCurrencyCode,
    });

    const currencies = await getCurrencyList({
      locale,
      preview,
      receiveCountryCode,
      sendCountryCode,
    });

    const corridors = await getCexCorridors();

    return {
      props: {
        sys: page.sys,
        __typename: page.__typename,
        name: page.name,
        title,
        description,
        noIndex: page.noIndex,
        analyticsPageName: page.analyticsPageName,
        footer: {
          ...page.footer,
          legalMessage: pageData?.legalMessagingCollection?.items[0],
        },
        header: {
          ...pageData?.layoutHeaderCollection?.items[0],
          submenuItems: page.submenuItemsCollection?.items,
          headerPosition: page.headerPosition,
          headerTheme: page.headerTheme,
        },
        breadcrumbs: interpolatedBreadcrumbs,
        modules,
        pageLinksWithRegionsAndSlugs,
        organizationSchema,
        originUri: process.env.WEB_CMS_SW_URI,
        countries: countries || [],
        messages: pageData?.messagesCollection?.items[0] || {},
        analyticsPageType: page?.analyticsPageType || '',
        locale: region,
        regionCode,
        regions,
        currencies,
        corridors,
      },
    };
  } catch (error) {
    logger.error({ error }, 'Error in getCurrencyConverterPageData');

    return {
      notFound: true,
    };
  }
};
