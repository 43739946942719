import { contentfulSchema } from '@wr/web-shared';

import { contentfulClient, logger } from '@/utils';

import { WebFormJsonSchema } from './web-form.types';

export const getContentfulWebFormJsonSchema = async ({
  id,
}: WebFormJsonSchema): Promise<contentfulSchema.WebFormJsonSchemaQuery> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.WebFormJsonSchemaQuery>({
      query: contentfulSchema.WebFormJsonSchema,
      variables: {
        id,
      },
    });

    return data;
  } catch (error) {
    logger.error(error, `getContentfulFeatureFlags request failed`);

    throw {
      name: 'WebFormJsonSchemaQuery',
      message: error,
    };
  }
};
