import {
  Block,
  BLOCKS,
  Inline,
  INLINES,
  Text,
} from '@contentful/rich-text-types';
import {
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { Image, LazyPDFViewer } from '@wr/web-ui';
import cloneDeep from 'lodash.clonedeep';
import React, { ReactNode } from 'react';

import { Anchor } from '@/components/anchor';
import { Button } from '@/components/button';
import { useStyles } from '@/components/rich-text-content-renderer/rich-text-content-renderer.styles';
import { logger } from '@/utils';

import { TextWithColor } from '../text-with-color';
// import RichTextContentRenderer from './rich-text-content-renderer.component';
import {
  HeadingBlocks,
  HeadingType,
  RichTextProps,
} from './rich-text-content-renderer.types';

export function ParagraphRenderer({ options }: Pick<RichTextProps, 'options'>) {
  return function Paragraph(
    node: Block | Inline,
    children: ReactNode,
  ): ReactNode {
    if (
      node.content.length === 1 &&
      node.content[0].nodeType === 'text' &&
      (node.content[0].value === '' || node.content[0].value === '↵↵')
    ) {
      return <br />;
    }

    const transformedNode = addCustomId(node);

    return (
      <Typography
        id={transformedNode.data?.id}
        component="p"
        paragraph
        {...options?.[BLOCKS.PARAGRAPH]}
      >
        {children}
      </Typography>
    );
  };
}

export function HeadingRenderer({ options }: Pick<RichTextProps, 'options'>) {
  return function Heading(
    node: Block | Inline,
    children: ReactNode,
  ): ReactNode {
    const tagMap: { [K in HeadingBlocks]: HeadingType } = {
      [BLOCKS.HEADING_1]: 'h1',
      [BLOCKS.HEADING_2]: 'h2',
      [BLOCKS.HEADING_3]: 'h3',
      [BLOCKS.HEADING_4]: 'h4',
      [BLOCKS.HEADING_5]: 'h5',
      [BLOCKS.HEADING_6]: 'h6',
    };

    const Tag = tagMap[node.nodeType as HeadingBlocks];

    const transformedNode = addCustomId(node);

    return (
      <Typography
        id={transformedNode.data?.id}
        component={Tag}
        variant={Tag}
        paragraph
        {...options?.[node.nodeType as HeadingBlocks]}
      >
        {children}
      </Typography>
    );
  };
}
export function UnorderedListRenderer({
  options,
}: Pick<RichTextProps, 'options'>) {
  return function UnorderedList(
    node: Block | Inline,
    children: ReactNode,
  ): ReactNode {
    return (
      <Typography component="ul" {...options?.[BLOCKS.UL_LIST]}>
        {children}
      </Typography>
    );
  };
}

export function OrderedListRenderer({
  options,
}: Pick<RichTextProps, 'options'>) {
  return function OrderedList(
    _: Block | Inline,
    children: ReactNode,
  ): ReactNode {
    return (
      <Typography component="ol" {...options?.[BLOCKS.OL_LIST]}>
        {children}
      </Typography>
    );
  };
}

export function ListItemRenderer({ options }: Pick<RichTextProps, 'options'>) {
  return function ListItem(_: Block | Inline, children: ReactNode): ReactNode {
    return (
      <Typography component="li" gutterBottom {...options?.[BLOCKS.LIST_ITEM]}>
        {children}
      </Typography>
    );
  };
}

export function EmbeddedEntryBlockRenderer({
  links,
  options,
}: Pick<RichTextProps, 'links' | 'options'>) {
  return function EmbeddedEntryBlock(node: Block | Inline): ReactNode {
    const { sys } = node.data.target;

    const fields = links?.entries?.block?.find(
      entry => entry?.sys.id === sys?.id,
    );

    switch (fields?.__typename) {
      case 'Link': {
        const linkFields = fields as contentfulSchema.LinkFragment;

        if (!linkFields?.isDisplayedWhenLoggedIn && options?.isAuthenticated) {
          return null;
        }

        return (
          <Button {...linkFields}>
            {linkFields.image ? (
              <Image {...linkFields.image} />
            ) : (
              linkFields.label
            )}
          </Button>
        );
      }
      // case 'Video': {
      //   return <Video {...fields} sys={sys} />;
      // }
      default: {
        return null;
      }
    }
  };
}

export function EmbeddedEntryInlineRenderer({
  links,
  options,
}: // classes,
Pick<RichTextProps, 'links' | 'options'> & {
  classes: ReturnType<typeof useStyles>;
}) {
  return function EmbeddedEntryInline(node: Block | Inline): ReactNode {
    const { sys } = node.data.target;

    const fields = links?.entries?.inline?.find(
      entry => entry?.sys.id === sys?.id,
    );

    switch (fields?.__typename) {
      case 'Link': {
        const linkFields = fields as contentfulSchema.LinkFragment;

        if (!linkFields?.isDisplayedWhenLoggedIn && options?.isAuthenticated) {
          return null;
        }

        return (
          <Anchor {...linkFields}>
            {linkFields.image ? (
              <Image {...linkFields.image} />
            ) : (
              linkFields.label
            )}
          </Anchor>
        );
      }
      case 'TextWithColor': {
        const linkFields = fields as contentfulSchema.TextWithColorFragment;

        return linkFields && <TextWithColor {...linkFields} />;
      }
      // case 'FaqModuleItem': {
      //   const {
      //     name,
      //     title,
      //   } = fields as contentfulSchema.FaqModuleItemFragment;

      //   return <Anchor url={`#${name}`}>{title}</Anchor>;
      // }
      // case 'IconWithText': {
      //   const { icon, text } = fields as contentfulSchema.IconWithTextFragment;

      //   return (
      //     <span className={classes.iconWithText}>
      //       {icon && <Image {...icon} className={classes.iconWithTextImage} />}
      //       {text?.text?.json && (
      //         <RichTextContentRenderer
      //           json={text.text.json}
      //           options={{
      //             [BLOCKS.PARAGRAPH]: {
      //               variant: 'inherit',
      //               component: 'span',
      //               paragraph: false,
      //             },
      //           }}
      //         />
      //       )}
      //     </span>
      //   );
      // }
      default: {
        return null;
      }
    }
  };
}

export function EmbeddedAssetRenderer({
  links,
  options,
  classes,
}: Pick<RichTextProps, 'links' | 'options'> & {
  classes: ReturnType<typeof useStyles>;
}) {
  return function EmbeddedAsset(node: Block | Inline): ReactNode {
    const { sys } = node.data.target;
    const fields = links?.assets?.block?.find(
      asset => asset?.sys.id === sys?.id,
    );

    if (fields) {
      if (fields.contentType === 'application/pdf') {
        return (
          <LazyPDFViewer
            sysID={sys.id}
            source={fields.url || ''}
            title={fields.title || ''}
            logger={logger}
          />
        );
      }

      return (
        <Image
          {...fields}
          {...options?.[BLOCKS.EMBEDDED_ASSET]}
          className={classes.imageAsset}
          width={
            (options?.[BLOCKS.EMBEDDED_ASSET]?.width as number) || fields.width
          }
          height={
            (options?.[BLOCKS.EMBEDDED_ASSET]?.height as number) ||
            fields.height
          }
          color={options?.[BLOCKS.EMBEDDED_ASSET]?.color as string}
        />
      );
    }

    return null;
  };
}

export function HyperLinkRenderer({ options }: Pick<RichTextProps, 'options'>) {
  return function HyperLink(
    node: Block | Inline,
    children: ReactNode,
  ): ReactNode {
    const isInternal =
      node.data.uri.startsWith('http://') ||
      node.data.uri.startsWith('https://')
        ? false
        : true;
    return (
      <Anchor
        url={node.data.uri || node.data.url}
        isInternal={isInternal}
        openInNewTab={!isInternal}
        {...options?.[INLINES.HYPERLINK]}
        color={options?.[INLINES.HYPERLINK]?.color as string}
      >
        {children}
      </Anchor>
    );
  };
}

// export function EntryHyperLinkRenderer({
//   links,
//   options,
// }: Pick<RichTextProps, 'links' | 'options'>) {
//   return function EntryHyperLink(
//     node: Block | Inline,
//     children: ReactNode,
//   ): ReactNode {
//     const { sys } = node.data.target;
//     const fields = links?.entries?.hyperlink?.find(
//       entry => entry?.sys.id === sys?.id,
//     );
//         return (
//           <Anchor
//             url={`#${name}`}
//             {...options?.[INLINES.HYPERLINK]}
//             color={options?.[INLINES.HYPERLINK]?.color as string}
//           >
//             {children}
//           </Anchor>
//         );
//       }
//     }
//   };
// }

export function TableRenderer({
  classes,
}: {
  classes: ReturnType<typeof useStyles>;
}) {
  return function Table(_: Block | Inline, children: ReactNode): ReactNode {
    // cannot render children directly inside Table, because of
    // validateDOMNesting(...): <tr> cannot appear as a child of <table>
    return Array.isArray(children) ? (
      <MuiTable size="small" className={classes.table}>
        <TableHead className={classes.tableHead}>{children?.[0]}</TableHead>
        <TableBody>{children?.slice(1)}</TableBody>
      </MuiTable>
    ) : null;
  };
}

export const TableRowRenderer = (_: Block | Inline, children: ReactNode) => {
  return <TableRow>{children}</TableRow>;
};

export function TableCellRenderer({
  classes,
}: {
  classes: ReturnType<typeof useStyles>;
}) {
  return function TableCell(_: Block | Inline, children: ReactNode): ReactNode {
    return (
      <MuiTableCell
        variant="body"
        size="medium"
        classes={{
          root: classes.tableCell,
        }}
      >
        {getParagraphWithoutMarginBottom(children)}
      </MuiTableCell>
    );
  };
}

export function TableHeaderCellRenderer({
  classes,
}: {
  classes: ReturnType<typeof useStyles>;
}) {
  return function TableHeaderCell(
    _: Block | Inline,
    children: ReactNode,
  ): ReactNode {
    return (
      <MuiTableCell
        size="medium"
        variant="head"
        classes={{
          root: classes.tableCell,
        }}
      >
        {getParagraphWithoutMarginBottom(children)}
      </MuiTableCell>
    );
  };
}

export const getRichTextHeadingOptions = (
  option: TypographyTypeMap<{
    component?: string;
    className?: string;
  }>['props'],
): Record<
  | BLOCKS.HEADING_1
  | BLOCKS.HEADING_2
  | BLOCKS.HEADING_3
  | BLOCKS.HEADING_4
  | BLOCKS.HEADING_5
  | BLOCKS.HEADING_6,
  TypographyTypeMap['props']
> => ({
  [BLOCKS.HEADING_1]: {
    ...option,
  },
  [BLOCKS.HEADING_2]: {
    ...option,
  },
  [BLOCKS.HEADING_3]: {
    ...option,
  },
  [BLOCKS.HEADING_4]: {
    ...option,
  },
  [BLOCKS.HEADING_5]: {
    ...option,
  },
  [BLOCKS.HEADING_6]: {
    ...option,
  },
});

export function addCustomId(node: Block | Inline): Block | Inline {
  const lastChild = node.content[node.content.length - 1];

  if (lastChild && lastChild.nodeType === 'text') {
    const search = / {#([^]+?)}$/; // search for curly braces with hashtag e.g. {#custom-id}
    const match = search.exec(lastChild.value);

    if (match) {
      const id = match[1];

      if (id.length) {
        const clonedNode = cloneDeep(node);
        const lastClonedChild = clonedNode.content[
          clonedNode.content.length - 1
        ] as Text;

        clonedNode.data.id = id;
        lastClonedChild.value = lastClonedChild.value.substring(0, match.index);

        return clonedNode;
      }
    }
  }

  return node;
}

export function getParagraphWithoutMarginBottom(children: React.ReactNode) {
  return React.Children.map(children, child => {
    return React.isValidElement(child)
      ? React.cloneElement(child, {
          paragraph: false,
        })
      : child;
  });
}

export function removeEmptyTextNode(arr: RichTextProps['json']['content']) {
  const lastObj = arr?.[arr.length - 1];
  if (
    lastObj &&
    lastObj?.content?.[0]?.nodeType === 'text' &&
    lastObj?.content?.[0].value === ''
  ) {
    return arr.slice(0, -1);
  }

  return arr;
}
