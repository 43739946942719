import { ANALYTICS_PAGE_TYPE, contentfulSchema } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import {
  BLOG_CARDS_PER_PAGE,
  DEFAULT_404_PAGE_SLUG,
  REFER_A_FRIEND_SLUG,
  SITEMAP_SLUG,
} from '@/constants';
import { contentfulClient, getPathsFromSlug, logger } from '@/utils';

import {
  PageDataQueryResult,
  PageQueryResult,
  PageQueryVariables,
} from './page.types';

/**
 * Returns Contentful Page based on parameters and saves it to mock file
 * to be accessible in case connection to Contentful is lost
 */
export const getPage = async ({
  slug = undefined,
  locale,
  region,
  preview,
  isBlogPage = false,
}: Pick<PageQueryVariables, 'slug'> & { region?: string } & Pick<
    GetServerSidePropsContext,
    'locale' | 'preview'
  > & {
    isBlogPage?: boolean;
  }): Promise<PageQueryResult> => {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.PageBySlugQuery | contentfulSchema.HomePageQuery
    >({
      query: slug ? contentfulSchema.PageBySlug : contentfulSchema.HomePage,
      variables: {
        slug,
        preview,
        locale,
        region,
        isBlogPage,
      },
    });

    return data?.pageCollection?.items[0] || null;
  } catch (error) {
    logger.error(
      error,
      `${slug ? 'PageBySlug' : 'HomePage'} query request failed`,
    );

    throw {
      name: (slug ? 'PageBySlug' : 'HomePage') + 'Query',
      message: error,
    };
  }
};

/**
 * Returns Contentful countries, payout methods labels, payout method alerts
 * and messages to be stored in AppContext
 */
export const getPageData = async ({
  pageId = '',
  locale,
  analyticsPageType,
  slug,
  preview = false,
  sendCountryCode,
}: // receiveCountryCode,
Partial<
  Pick<
    GetServerSidePropsContext,
    'locale' | 'preview' | 'resolvedUrl' | 'query'
  >
> &
  Pick<NonNullable<PageQueryResult>, 'analyticsPageType' | 'slug'> & {
    pageId?: string;
    sendCountryCode: string;
    // receiveCountryCode: string | undefined;
    cookies?: GetServerSidePropsContext['req']['cookies'];
  }): Promise<PageDataQueryResult> => {
  const isCorrespondentPage =
    analyticsPageType === ANALYTICS_PAGE_TYPE.CountryOperator;
  const includeRelatedPages =
    analyticsPageType === ANALYTICS_PAGE_TYPE.News ||
    analyticsPageType === ANALYTICS_PAGE_TYPE.Blog;
  const paths = slug ? getPathsFromSlug(slug) : [];

  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.PageDataQuery>({
      query: contentfulSchema.PageData,
      variables: {
        pageId,
        locale,
        preview,
        sendCountryCode,
        // receiveCountryCode,
        isCorrespondentPage,
        slug,
        paths,
        includeRelatedPages,
      },
    });

    return data;
  } catch (error) {
    logger.error(error, `PageData query request failed`);

    throw {
      name: 'PageDataQuery',
      message: error,
    };
  }
};

export const getCountryPagesSlugs = async ({
  locale,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'>): Promise<
  string[] | null
> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.CountryPagesSlugsQuery>({
      query: contentfulSchema.CountryPagesSlugs,
      variables: {
        locale: locale,
        analyticsPageType: ANALYTICS_PAGE_TYPE.Country,
      },
    });

    const result =
      data?.pageCollection?.items
        .filter((i): i is { slug: string } => Boolean(i?.slug))
        .map(i => i.slug) || null;

    return result;
  } catch (error) {
    logger.error(error, `CountryPagesSlugsQuery query request failed`);

    throw {
      name: 'CountryPagesSlugsQuery',
      message: error,
    };
  }
};

export const getPagesSitemapLinks = async ({
  locale,
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'>): Promise<
  NonNullable<
    contentfulSchema.PagesSitemapLinksQuery['pageCollection']
  >['items']
> => {
  try {
    const result: NonNullable<
      contentfulSchema.PagesSitemapLinksQuery['pageCollection']
    >['items'] = [];

    for (let skip = 0; skip < 10000; skip += 1000) {
      const {
        data,
      } = await contentfulClient.query<contentfulSchema.PagesSitemapLinksQuery>(
        {
          query: contentfulSchema.PagesSitemapLinks,
          variables: {
            skip,
            preview,
            locale,
            excludedSlugs: [
              DEFAULT_404_PAGE_SLUG,
              SITEMAP_SLUG,
              REFER_A_FRIEND_SLUG, // TODO: remove when page is ready
            ],
          },
        },
      );

      if (data?.pageCollection?.items.length) {
        result.push(...data.pageCollection.items);
      } else {
        break;
      }
    }

    return result;
  } catch (error) {
    logger.error(error, `PagesSitemapLinks query request failed`);

    return [];
  }
};

export async function getBlogPagesCards({
  analyticsPageType,
  locale,
  preview,
  skip,
  tags,
  title,
}: Pick<NonNullable<PageQueryResult>, 'analyticsPageType'> &
  Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
    tags: string[];
    title?: string;
    skip?: number;
  }): Promise<NonNullable<contentfulSchema.BlogPagesCardsQuery>> {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.BlogPagesCardsQuery>({
      query: contentfulSchema.BlogPagesCards,
      variables: {
        analyticsPageType,
        preview,
        locale,
        tags,
        title,
        shouldIncludeSearchOptions: false,
        skip,
        limit: BLOG_CARDS_PER_PAGE,
      },
    });

    return data;
  } catch (error) {
    logger.error(error, `BlogPagesCardsQuery query request failed`);

    return {};
  }
}

export async function getBlogPagesTags({
  analyticsPageType,
  locale,
  preview,
}: Pick<NonNullable<PageQueryResult>, 'analyticsPageType'> &
  Pick<GetServerSidePropsContext, 'locale' | 'preview'>): Promise<
  NonNullable<contentfulSchema.BlogPagesTagsQuery['pageCollection']>['items']
> {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.BlogPagesTagsQuery>({
      query: contentfulSchema.BlogPagesTags,
      variables: {
        analyticsPageType,
        preview,
        locale,
      },
    });

    return data?.pageCollection?.items || [];
  } catch (error) {
    logger.error(error, `BlogPagesTagsQuery query request failed`);

    return [];
  }
}
