import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(theme => ({
  tableContainer: {
    width: '100%',
    overflow: 'auto',
  },
  table: {
    tableLayout: 'fixed',
  },
  tableHead: {
    backgroundColor: colors.solitude,
  },
  tableCell: {
    'border': `1px solid ${colors.solitude}`,
    'verticalAlign': 'baseline',

    '& p:last-of-type': {
      marginBottom: 0,
    },
  },
  iconWithText: {
    display: 'flex',
  },
  iconWithTextImage: {
    marginRight: theme.spacing(1),
  },
  imageAsset: {
    maxWidth: '100%',
    height: 'auto',
  },
}));
